import React from "react"
import { StaticImage, getImage} from "gatsby-plugin-image"
import Layout from "../components/layout"
import Case_study from "../components/case_study"
import Testimonials from "../components/testimonials"
import Faqs from "../components/faqs.js"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import Seo from "../components/seo"
import { useStaticQuery, graphql, Link } from "gatsby"



const ShopifyPartner = (ecommerce_services) => { 

   const case_study_slider = ecommerce_services.data.allWpCaseStudySlider;

    //console.log('Case', ecommerce_services)

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        pauseOnHover: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrow:false,
        responsive: [
         {
           breakpoint: 768,
           settings: {
             slidesToShow: 2,
             slidesToScroll: 1,
            
           }
         },
         {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
             
            }
          }
         ]
      };


      const handleSpecialDiscountSubmit = (e) => {
         let myForm = document.getElementById('shopify_ppc_specialdiscount_form');
     let formData = new FormData(myForm)
   
           fetch("/", {
           method: "POST",
           headers: { "Content-Type": "application/x-www-form-urlencoded" },
           body: new URLSearchParams( formData).toString()
         })
           .then(() =>{
   
             document.getElementById("response_msg_shopify_discount").innerHTML = 
             `<div class="alert alert-success" role="alert">
             Thank You for contacting us. We will get back in touch with you soon!
             </div>`;
            // alert("Success!");
             document.getElementById("shopify_ppc_specialdiscount_form").reset();
             window.scrollTo(0, 200);
   
           })
           .catch(error => alert(error));
   
         e.preventDefault();
   
      }


      
      const handleTalkCustomerSubmit = (e) => {
         let myForm = document.getElementById('shopify_ppc_talkcustomer_form');
     let formData = new FormData(myForm)
   
           fetch("/", {
           method: "POST",
           headers: { "Content-Type": "application/x-www-form-urlencoded" },
           body: new URLSearchParams( formData).toString()
         })
           .then(() =>{
   
             document.getElementById("response_msg__talkcustomer_form").innerHTML = 
             `<div class="alert alert-success" role="alert">
             Thank You for contacting us. We will get back in touch with you soon!
             </div>`;
            // alert("Success!");
             document.getElementById("shopify_ppc_talkcustomer_form").reset();
            // window.scrollTo(0, 200);
   
           })
           .catch(error => alert(error));
   
         e.preventDefault();
   
      }





    return (

        <Layout>
          
<section className = "top_banner">
        
        <div className="container">

            
            <div className = "row">

                        <div className = "baner_cont">
                            <h1>Work with Shopify Partner Agency </h1>
                            
                            <ul>
                                <li>create</li>
                                <li>upgrade</li>
                                <li>manage</li>
                            </ul>

                            <h2>Your Shopify Website No More Retainer!</h2>
                            <Link to ="https://api.leadconnectorhq.com/widget/appointment/generalcalendar-estorecompany/discovery-call-for-virtual-assistant" className = "schedule_calls">schdule a call now</Link>

                        </div>

                        <div className = "baner_forn">
                        <StaticImage
                            src="../images/shopify_ppc_graph.png"
                            quality = "100"
                            
                            alt="A Gatsby astronaut"
                            placeholder="tracedSVG"
                            />

                            <div className="form_part">
                                   <h2>Fill the form to and get special discount</h2>     
                                    <h1>Avail Today !</h1>
             <form onSubmit = {handleSpecialDiscountSubmit} className = "shopify_ppc_specialdiscount"  id ="shopify_ppc_specialdiscount_form" name="Shopify Special Discount" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
           
             <input type="hidden" name="form-name" value = "Shopify Special Discount" />

            <div className = "fields"> <input type="text" name="name"  placeholder = "Your Name" required/></div>
            <div className = "fields"> <input type="email" name="email" placeholder = "Your Email" required /></div>
            <div className = "fields"> <input type="tel" name="phone" placeholder = "Your Phone"   required  /></div>

            <textarea placeholder = "Write message" name = "message"></textarea>

            <button type = "submit">get a quote!</button>

<div id = "response_msg_shopify_discount"></div>
</form>
                            </div>

                            
                        </div>

            </div>

            
            
        </div>
    
</section>
  
<section className = "ab_batches partners">

<div className ="container">
<h1>our partners</h1>
<div className = "batches">

<ul>
  <li> <StaticImage
    src="../images/client-logo1.png"
    quality = "100"
     alt="A Gatsby astronaut" 
     placeholder="tracedSVG"
     /></li>
  <li><StaticImage
    src="../images/client-logo6.png"
    quality = "100"
     alt="A Gatsby astronaut"
     placeholder="tracedSVG"
     /></li>
  <li><StaticImage
    src="../images/client-logo8.png"
    quality = "100"
     alt="A Gatsby astronaut"
     placeholder="tracedSVG"
     /></li>
  <li><StaticImage
    src="../images/client-logo2.png"
    quality = "100"
     alt="A Gatsby astronaut"
     placeholder="tracedSVG"
     /></li>
  <li><StaticImage
    src="../images/client-logo3.png"
    quality = "100"
     alt="A Gatsby astronaut" 
     placeholder="tracedSVG"
     /></li>
  <li><StaticImage
    src="../images/client-logo7.png"
    quality = "100"
     alt="A Gatsby astronaut"
     placeholder="tracedSVG"
     /></li>

    </ul>
</div>
</div>
</section>

<section className="top_banner oneshop">
    <div className="container">
        <div className="row">
            <div className="baner_cont">
                <h3>your one stop shop for all shopify services</h3>
                <p>Estore Company is your one-stop shop for all elements required in Shopify Store Development. 
                  We have the expertise to help 
                  you in every area of your business; from strategy development, custom design and 
                  development, theme installation, theme customization and app installation and many more. </p>
                     <Link to ="https://api.leadconnectorhq.com/widget/appointment/generalcalendar-estorecompany/discovery-call-for-virtual-assistant" className = "schedule_calls">schdule a call now</Link>
                     </div>
                     <div className="baner_forn">
                         <div className="form_part">
                             <h2>Talk Customer Success Manager Now</h2>
                             
                             <form onSubmit = {handleTalkCustomerSubmit} className="shopify_ppc_talkcustomer_form" id="shopify_ppc_talkcustomer_form" name="Shopify Talk Customer" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
                                
             <input type="hidden" name="form-name" value = "Shopify Talk Customer" />
                                 <div className="fields"> 
                                 <input type="text" name="name" placeholder="Enter Your Name" required />
                                     </div><div className="fields"> 
                                     <input type="email" name="email" placeholder="Enter Email" required />
                                         </div>
                                         <div className="fields"> 
                                         <input type="tel" name="phone" placeholder="Enter Phone Number" required />
                                         </div>
                                         <textarea placeholder="Message" name="message">
                                             </textarea>
                                             <button type="submit">get a free quote!</button>
                                             <div id="response_msg__talkcustomer_form">
                                                 </div>
                                                 </form>
                                                 
                                                 </div>
                                                 </div>
                                                 </div>
                                                 </div>
</section>




<section className= "ur_sho_ser">
    
    <div className="container">
        
    <h1>our shopify services</h1>
            <h3>not one services fits all</h3>
    <p>
    Estorecompany is a pioneer in digital shopify store development and has been 
    delivering several projects for over a decade. Our vetted experts provide high-quality 
    ecommerce services including front-end design, ecommerce development, ecommerce platforms, 
    customization, integrations, maintenance and support. Along with shopify, we deal in all major
     ecommerce platforms:  Magento, BigCommerce, WooCommerce and others. You can always count on us for 
     best-in-class services.
     Make us your ecommerce growth partner, you’ll never regret it.

    </p>
    <ul>
    <Slider {...settings}>
    
   <li className="first">
      <h3>DEVELOPMENT </h3>
      <p>We can help you build a modern storefront in a stress-free way - all as per your wish list, 
        budget and timeline. From theme development, storefront design to app development, migration and maintenance.</p>
   </li>
   <li className="first">
      <h3>DESIGN </h3>
      <p>Don’t settle for less, get flawless and pixel-perfect designs. From theme design, customization to experience
         design and interaction design - we do it all. Our designs are optimized for mobile, user engagement and intuitiveness.</p>
   </li>
   <li className="first">
      <h3>APP DEVELOPMENT </h3>
      <p>Shopify’s app marketplace is getting bigger by each passing day.If you wish to develop a Shopify app for your store or marketplace - we can gladly do that for you. Also let us know if you like to
         use existing set of apps in your store’s favor.</p>
   </li>
   <li className="first">
      <h3>MIGRATION</h3>
      <p>Migration was never this easy, thanks to our stress-free migration solution. Try our risk free re-platforming 
        service, we can help you get going in no time. Don’t worry about products, customers and vendors data - we’ll take
         care of it.</p>
   </li>


   <li className="first">
      <h3>INTEGRATIONS</h3>
      <p>Connect your existing systems or external apps without any friction or hassle. We can build a bridge
         between Shopify and any channel as per your need including: CRM, ERP, marketplaces, POS and more - don’t fret, 
         we do it in a breezy way.</p>
   </li>

   <li className="first">
      <h3>SUPPORT AND MAINTENANCE</h3>
      <p>Let us deal with all the Shopify worries and headaches, at a fraction of cost. We can give 24 hours of support 
        to keep your store at an optimum efficiency.You can count on us for an uninterrupted experience.</p>
   </li>
 



    </Slider>
    </ul>

</div>
    </section>

<Case_study data_case_slider = {case_study_slider}/>


<section className="price_plans">

      <div className = "container">

          <h1>price plans</h1>

            <div className = "package_box">

          <h2>$ 2499</h2>
          <h3>5 pages</h3>

            <ul>
            <li><i class="fa fa-check"></i> <h6>Custom designer homepage</h6></li>
            <li><i class="fa fa-check"></i> <h6>Upto 5 to 50 products</h6></li>
            <li><i class="fa fa-check"></i> <h6>Content management system</h6></li>
            <li><i class="fa fa-check"></i> <h6>Mini shopping cart integration</h6></li>
            <li><i class="fa fa-check"></i> <h6>social media integration</h6></li>
            <li><i class="fa fa-check"></i> <h6>2 promotional banners</h6></li>
            <li><i class="fa fa-check"></i> <h6>cross browser compatiblity</h6></li>
            <li><i class="fa fa-check"></i> <h6>5 custom designed inner pages</h6></li>
            <li><i class="fa fa-check"></i> <h6>Upto 7 categories</h6></li>
            <li><i class="fa fa-check"></i> <h6>Sales & inventory management</h6></li>
            <li><i class="fa fa-check"></i> <h6>Payment gateway integration</h6></li>
            <li><i class="fa fa-check"></i> <h6>Easy product search</h6></li>
            <li><i class="fa fa-check"></i> <h6>Interactive jquery slider</h6></li>
            <li><i class="fa fa-check"></i> <h6>W3c certified HTML</h6></li>








            <Link to ="https://api.leadconnectorhq.com/widget/appointment/generalcalendar-estorecompany/discovery-call-for-virtual-assistant" className = "schedule_calls" >schdule a call now</Link>





            </ul>

            </div>

      </div>

</section>


<section class="full_services our_steps">
   <div class="container">
      <div>
         <h2>our process</h2>
         <h6>proved many time to successful execution</h6>
      </div>
      <ul>
         <li class="first">
            <h3>step 01</h3>
            <h3>project discusion</h3>
            <p>Hit sales target, convince users to buy more or stay a little longer. You can actually do more, talk to us and we’ll let you know the prefect recipe. From ecommerce design to on-demand development - we do it all.</p>
         </li>
         <li class="first">
            <h3>step 02 </h3>
            <h3>strategy  development</h3>
            <p>You need thoughtful web designs that delight users, foster sales and make users fall in love with your business. It took us a little over a decade to learn that exact art. We truly specialize in intuitive UI UX and pixel-perfect designs.</p>
         </li>
         <li class="first">
            <h3>step 03 </h3>
            <h3>website design</h3>
            <p>Futuristic websites that are both customer-centric and able to generate more ROI along with user engagement. Who doesn’t want that! We can help you get there through superior web development skills. CMS, custom build or ecommerce platforms - don’t you worry!</p>
         </li>
         <li class="first">
            <h3>step 04 </h3>
            <h3>website development </h3>
            <p>Reach, engage and sell more to your customers with our targeted digital campaigns. From SEO, search engine marketing to social commerce and programmatic - we do it all. You get exceptional ROI and more market share - guaranteed!</p>
         </li>

         <li class="first">
            <h3>step 05 </h3>
            <h3>perform a test </h3>
            <p>Reach, engage and sell more to your customers with our targeted digital campaigns. From SEO, search engine marketing to social commerce and programmatic - we do it all. You get exceptional ROI and more market share - guaranteed!</p>
         </li>
         <li class="first">
            <h3>step 06 </h3>
            <h3>time to go live</h3>
            <p>Reach, engage and sell more to your customers with our targeted digital campaigns. From SEO, search engine marketing to social commerce and programmatic - we do it all. You get exceptional ROI and more market share - guaranteed!</p>
         </li>
      </ul>
      <Link to ="https://api.leadconnectorhq.com/widget/appointment/generalcalendar-estorecompany/discovery-call-for-virtual-assistant" className = "schedule_calls" >schdule a call now</Link>
   </div>
</section>

<Testimonials />
<Faqs/>


        </Layout>



    )


}

export const services = graphql`

query allCaseStudyPost {
    allWpCaseStudySlider {
        edges {
          node {
            caseStudySlider {
              projectName
              projectStack
              projectLogo {
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED,  width: 70,quality: 100)
                  }
                }
              }
              projectImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED,quality: 100)
                  }
                }
              }
            }
          }
        }
      }

}
`;

export default ShopifyPartner