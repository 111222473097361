import * as React from "react"
import { useStaticQuery, graphql, withPrefix, Link } from "gatsby"
import Slider from 'react-animated-slider'
import { StaticImage, GatsbyImage, getImage} from "gatsby-plugin-image"

/*
const slides = [


  { maintitle: 'Featured Work ', shortdescription: 'Each project is dear to our hearts, we own it and never shy to go miles for its success and that’s exactly what makes us a preferred choice. ',
  projectlogo : '../images/instas_fav.png', projectname : 'greenum market', projectstacks : 'product strategy, ui/ux design, integration design',
  projectdisplay : '../images/insta_ban.png'},
  { maintitle: 'Featured Work', shortdescription: 'Each project is dear to our hearts, we own it and never shy to go miles for its success and that’s exactly what makes us a preferred choice. ',
  projectlogo : '../images/honey_fav.png', projectname : 'Elesccoo', projectstacks : 'product strategy, ui/ux design, integration design',
  projectdisplay : '../images/honey_ban.png'},
  { maintitle: 'Featured Work', shortdescription: 'Each project is dear to our hearts, we own it and never shy to go miles for its success and that’s exactly what makes us a preferred choice. ',
  projectlogo : '../images/kix_fav.png', projectname : 'KIX', projectstacks : 'product strategy, ui/ux design, integration design',
  projectdisplay : '../images/kix_ban.png'}


];
*/

const Case_study = (props) => {

  const slides = props.data_case_slider.edges;
  //console.log('case study banners',props.data_case_slider );

    return (

        <section className = "case_stude">

<Slider autoplay = "2000"  onSlideChange={event => 
  console.log(event.slideIndex)}>
  {slides.map((slide, index) => 
  <div className ="as" key={index}>
    <div className ="info">
    <h2>Featured Work</h2>
    <p>Each project is dear to our hearts, we own it and never shy to go miles for its success and that’s exactly what makes us a preferred choice.</p>
    <div className  = "case_study_web">

    <GatsbyImage
      image={getImage(slide.node.caseStudySlider.projectLogo.localFile)}
     
      alt={slide.node.caseStudySlider.projectName}
      />

    
      <h1>{slide.node.caseStudySlider.projectName}</h1>
      <h3>{slide.node.caseStudySlider.projectStack}</h3>
    </div>
    </div>
    <div className = "casestudy_img  animated bounce">


    <GatsbyImage
      image={getImage(slide.node.caseStudySlider.projectImage.localFile)}
     
      alt={slide.node.caseStudySlider.projectName}
      />

      

    </div>
  </div>
  )}
</Slider>
<div className = "container">
<Link to = "/case-study"  className = "a_btn">View <strong>Projects</strong></Link>
</div>
</section>

        )



    }


   

    
    
    export default Case_study;
